@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:wght@300;400;500;600;700&display=swap');

@import url('https://api.fontshare.com/v2/css?f[]=sharpie@400&f[]=telma@400,500&f[]=montserrat@400&display=swap');


/* App.css */

/* Define the keyframes for the background animation */
/* @keyframes changeBackground {
  0% {
    background-image: url('./images/image1.webp');
  }

  33% {
    background-image: url('./images/image2.webp');
  }

  66% {
    background-image: url('./images/image3.webp');
  }

  100% {
    background-image: url('./images/image2.webp');
  }
} */

/* Apply the animation to the container */
/* .bg-container {
  animation: changeBackground 15s infinite;

  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
} */

/* .font{
  font-family: '';
} */

.bg-container::before{
  content: "";
  position: absolute;
  /* top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px; */
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.627);
  z-index: 0;

}


